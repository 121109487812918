import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from '../components/Layout/Sidebar';
import TopNavbar from '../components/Layout/TopNavbar';
import Footer from '../components/Layout/Footer';
import FloatMenu from '../components/Layout/FloatMenu';
import Dashboard from '../components/Dashboard/dashboard';
import Pms from '../components/PMS/Pms';
import OrgChart from '../components/RoadMap/OrgChart';
import User from '../components/Authentication/Users/User';
import UserProfile from '../components/Authentication/Users/UserProfile';
import Menu from '../components/Authentication/Menus/Menu';
import Role from '../components/Authentication/Roles/Role';
import Activity from '../components/Authentication/Activity/Activity';
import RecoverPassword from '../components/Auth/RecoverPassword';
import ResetPassword from '../components/Auth/ResetPassword';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Bi from "../components/BI/Bi";
import Services from "../components/XYOU/Services";
import Embroidery from "../components/XYOU/Xyou";
import Alteration from "../components/XYOU/Alteration";
import Monogramming from "../components/XYOU/Monogramming";
import Asset from '../components/XYOU/Setup/Assets';
import Product from '../components/XYOU/Setup/Products';
import Fonts from '../components/XYOU/Setup/FontPage';
import ColorPage from '../components/XYOU/Setup/ColorPage';
import DesignsPage from '../components/XYOU/Setup/DesignsPage';
import OrdersPage from '../components/XYOU/Setup/OrdersPage';
import DiscountDetails from '../components/ESS/DiscountDetail/DiscountDetails';
import DigitalProfile from '../components/ESS/DiscountDetail/DigitalProfile';
import ServiceRequest from '../components/ESS/ServiceRequest/ServiceRequests';
import Requests from '../components/ESS/Requests/Request';
import WorkDesk from '../components/ESS/WorkDesk/WorkDesk';
import TaskDetail from '../components/ESS/Requests/TaskDetail';
import Signature from '../components/Signature/Signature';
import Company from '../components/Organization/Company/Company';
import Employee from '../components/Organization/Employees/Employee';
import Location from '../components/Organization/Location/Location';
import Department from "../components/Organization/Department/Department";
import MainType from "../components/Organization/MainType/MainType";
import TicketType from "../components/Organization/TicketType/TicketType";
import Discounts from "../components/ESS/DiscountDetail/Discounts";
import SRDashboard from "../components/ESS/SRDashboard/SRDashboard";
import BusinessCard from "../components/ESS/DiscountDetail/BusinessCard";
import ProjectDashboard from "../components/Project/ProjectDashboard";
import IndividualDashboard from "../components/Project/IndividualDashboard";
import TeamDashboard from "../components/ESS/TeamDashboard/TeamDashboard";

const App = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [menus, setMenus] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState(null);

    useEffect(() => {
        const data = JSON.parse(document.getElementById('current-user').textContent);
        setCurrentUser(data.current_user);
        setMenus(data.menus);
        setAvatarUrl(data.avatar_url);
    }, []);

    if (!currentUser && !menus && window.location.pathname !== '/roadmaps') {
        return <div>Loading...</div>;
    }
    return (
        <Router>
            <Routes>
                <Route path="/recover" element={<RecoverPassword />} />
                <Route path="/reset_password" element={<ResetPassword />} />
                {!currentUser && (
                    <Route path="/roadmaps" element={<OrgChart />} />
                )}
                <Route
                    path="/*"
                    element={
                        <Layout
                            currentUser={currentUser}
                            avatarUrl={avatarUrl}
                            menus={menus}
                            dashboard_path="/dashboard"
                            pms_path="/pms"
                            bi_path="/clean_beirholm/bi"
                            road_map_path="/roadmaps"
                            x_you_path="/sapphire_x_you/x_you"
                            discount_detail_path="/discount_details"
                            discounts_path="/discount_details/discounts"
                            service_request_path="/service_requests"
                            sr_dashboards_path="/sr_dashboards"
                            team_dashboards_path="/team_dashboards"
                            projects_path="/projects"
                            digital_profile_path="/digital_profile"
                            request_path="/requests"
                            work_desk_path="/work_desk"
                            signature_path="/signatures/create_"
                            company_path="/companies"
                            employees_path="/employees"
                            location_path="/locations"
                            department_path="/departments"
                            main_type_path="/main_types"
                            ticket_type_path="/ticket_types"
                            user_path="/user"
                            menu_path="/menu"
                            role_path="/role"
                            user_profile_path="/user_profile"
                            activity_stream_path="/activity_stream"
                            link_target_attribute="_blank"
                        />
                    }
                />
            </Routes>
        </Router>
    );
};

const Layout = ({
                    currentUser,
                    avatarUrl,
                    menus,
                    dashboard_path,
                    pms_path,
                    bi_path,
                    road_map_path,
                    x_you_path,
                    discount_detail_path,
                    discounts_path,
                    service_request_path,
                    sr_dashboards_path,
                    team_dashboards_path,
                    projects_path,
                    request_path,
                    digital_profile_path,
                    work_desk_path,
                    task_path,
                    signature_path,
                    company_path,
                    employees_path,
                    location_path,
                    department_path,
                    main_type_path,
                    ticket_type_path,
                    user_path,
                    menu_path,
                    role_path,
                    user_profile_path,
                    activity_stream_path,
                    link_target_attribute
                }) => {
    const location = useLocation();
    const nodeRefs = useRef({});

    if (!nodeRefs.current[location.key]) {
        nodeRefs.current[location.key] = React.createRef();
    }

    return (
        <div className="wrapper">
            <div className="iq-sidebar">
                <Sidebar
                    currentUser={currentUser}
                    menus={menus}
                    dashboard_path={dashboard_path}
                    pms_path={pms_path}
                    bi_path={bi_path}
                    road_map_path={road_map_path}
                    x_you_path={x_you_path}
                    discount_detail_path={discount_detail_path}
                    discounts_path={discounts_path}
                    digital_profile_path={digital_profile_path}
                    service_request_path={service_request_path}
                    sr_dashboards_path={sr_dashboards_path}
                    team_dashboards_path={team_dashboards_path}
                    projects_path={projects_path}
                    request_path={request_path}
                    work_desk_path={work_desk_path}
                    task_path={task_path}
                    signature_path={signature_path}
                    company_path={company_path}
                    employees_path={employees_path}
                    location_path={location_path}
                    department_path={department_path}
                    main_type_path={main_type_path}
                    ticket_type_path={ticket_type_path}
                    user_path={user_path}
                    menu_path={menu_path}
                    role_path={role_path}
                    user_profile_path={user_profile_path}
                    activity_stream_path={activity_stream_path}
                    link_target_attribute={link_target_attribute}
                />
            </div>
            <div className="iq-top-navbar">
                <TopNavbar currentUser={currentUser} avatarUrl={avatarUrl} />
            </div>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <TransitionGroup component={null}>
                        <CSSTransition
                            key={location.key}
                            classNames="dialog"
                            timeout={300}
                            nodeRef={nodeRefs.current[location.key]}
                        >
                            <div ref={nodeRefs.current[location.key]}>
                                <Routes location={location}>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/pms" element={<Pms />} />
                                    <Route path="/roadmaps" element={<OrgChart />} />
                                    <Route path="/clean_beirholm/bi" element={<Bi />} />
                                    <Route path="/sapphire_x_you/x_you" element={<Services />} />
                                    <Route path="/sapphire_x_you/alteration" element={<Alteration />} />
                                    <Route path="/sapphire_x_you/embroidery" element={<Embroidery />} />
                                    <Route path="/sapphire_x_you/monogramming" element={<Monogramming />} />
                                    <Route path="/sapphire_x_you/asset" element={<Asset />} />
                                    <Route path="/sapphire_x_you/product" element={<Product />} />
                                    <Route path="/sapphire_x_you/fonts" element={<Fonts />} />
                                    <Route path="/sapphire_x_you/colors" element={<ColorPage />} />
                                    <Route path="/sapphire_x_you/designs" element={<DesignsPage />} />
                                    <Route path="/sapphire_x_you/orders" element={<OrdersPage />} />
                                    <Route path="/discount_details" element={<DiscountDetails currentUser={currentUser} />} />
                                    <Route path="/digital_profile" element={<DigitalProfile currentUser={currentUser} />} />
                                    <Route path="/digital_profile/profile" element={<BusinessCard currentUser={currentUser} />} />
                                    <Route path="/discount_details/discounts" element={<Discounts />} />
                                    <Route path="/service_requests" element={<ServiceRequest currentUser={currentUser} avatarUrl={avatarUrl} />} />
                                    <Route path="/requests" element={<Requests currentUser={currentUser} avatarUrl={avatarUrl} />} />
                                    <Route path="/sr_dashboards" element={<SRDashboard currentUser={currentUser} />} />
                                    <Route path="/team_dashboards" element={<TeamDashboard currentUser={currentUser} />} />
                                    <Route path="/projects" element={<ProjectDashboard currentUser={currentUser} />} />
                                    <Route path="/projects/:id/individual_dashboard" element={<IndividualDashboard currentUser={currentUser} />} />
                                    <Route path="/work_desk" element={<WorkDesk  currentUser={currentUser} avatarUrl={avatarUrl} />} />
                                    <Route path="/service_requests/:id/details" element={<TaskDetail currentUser={currentUser} />} />
                                    <Route path="/signatures/create_" element={<Signature />} />
                                    <Route path="/user" element={<User />} />
                                    <Route path="/companies" element={<Company />} />
                                    <Route path="/employees" element={<Employee />} />
                                    <Route path="/locations" element={<Location />} />
                                    <Route path="/departments" element={<Department />} />
                                    <Route path="/main_types" element={<MainType />} />
                                    <Route path="/ticket_types" element={<TicketType />} />
                                    <Route path="/menu" element={<Menu />} />
                                    <Route path="/role" element={<Role />} />
                                    <Route path="/user_profile" element={<UserProfile currentUser={currentUser} avatarUrl={avatarUrl}/>} />
                                    <Route path="/activity_stream" element={<Activity />} />
                                </Routes>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
            <footer className="iq-footer">
                <Footer />
            </footer>
            <nav className="iq-float-menu">
                <FloatMenu />
            </nav>
        </div>
    );
};

export default App;
